<template>
  <div class="page-container">
    <div class="content-wrap">
    <TopNavBar/>
    <br>
    <b-container fluid="true">
      <b-row>
        <b-col class="col-0 col-sm-0 col-md-1 col-lg-2"></b-col>
        <b-col class="col-12 col-sm-12 col-md-10 col-lg-8">
          <b-card
            class="mt-5"
            :title="$t('Add team member')"
          >

            <div class="pt-3" v-if="memberSuccessfullyAdded">
              <b-alert show dismissible variant="success">
                {{$t(memberSuccessfullyAdded)}}
              </b-alert>
            </div>
            <b-form @reset="onReset" @submit="onSubmit" v-if="show">
              <b-row>
                <!--memberEmail-->
                <b-col md="12" sm="12">
                  <b-form-group
                    id="memberEmail"
                    :label="$t('Member email:')"
                    label-for="memberEmail"
                  >
                    <b-form-input
                      id="memberEmail"
                      :placeholder="$t('Emilie.Kempin-Spyri@bluewin.ch')"
                      type="text"
                      v-model="memberEmail"
                    ></b-form-input>
                  </b-form-group>

                  <div class="" v-if="$v.$error">
                    <small class=" form-text text-danger" v-if="!$v.memberEmail.required">{{$t('Team member email is required')}}</small>
                  </div>
                </b-col>
              </b-row>

              <div v-if="error">
                <b-alert show variant="info">
                  {{error}}
                </b-alert>
              </div>

              <div v-if="success">
                <b-alert show variant="success">
                  {{success}}
                </b-alert>
              </div>

              <div class="mt-3">
                <b-button class="mr-3" type="submit" variant="primary">{{$t('Add Member')}}</b-button>
                <b-button class="mr-3" type="button" variant="secondary" @click="backToDashboard()">{{$t('Back to dashboard')}}</b-button>
              </div>

              <div class="pt-3" v-if="memberFound && !memberApplicable">
                <b-alert show dismissible variant="info">
                  {{$t('We found a matching account but the user can\'t be added to your team. Make sure they don\'t already belong to a team and are no team admin.')}}
                </b-alert>
              </div>
            </b-form>
            <b-card class="mt-5 border-success" v-if="memberFound && memberApplicable">
              <b-row>
                <b-col md="12" sm="12">
                  <div class="">{{ $t('We found the following user: ') }}<a class="font-weight-bold" target="_blank" :href="'/profile/' + foundMemberName">{{foundMemberName}}</a></div>
                  <div class="">{{ $t('Do you want to add them to your team?') }}</div>
                </b-col>
              </b-row>
              <div class="mt-3">
                <b-button class="mr-3" variant="success" @click="assignExistingMemberToTeam()">{{$t('yes')}}</b-button>
              </div>
            </b-card>
          </b-card>
          <AddTeamMemberNewAccount v-if="memberAddNew"
          :memberEmail="memberEmail"
          v-on:newMemberCreated="afterNewUserCreation($event)"
          ></AddTeamMemberNewAccount>
        </b-col>
      </b-row>
    </b-container>
  </div>
    <GenderAppFooter/>
  </div>
</template>

<script>
import TopNavBar from '../../components/TopNavBar'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import GenderAppFooter from '../../components/Footer'
import AddTeamMemberNewAccount from './AddTeamMemberNewAccount'
import { TeamService } from '@/services/TeamService'

export default {
  name: 'AddTeamMember',
  data () {
    return {
      memberEmail: '',
      show: true,
      error: '',
      memberSuccessfullyAdded: '',
      memberAddNew: '',
      memberPk: null,
      memberFound: null,
      memberApplicable: '',
      foundMemberName: '',
      success: ''
    }
  },
  mixins: [validationMixin],
  validations: {
    memberEmail: {
      required
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.error = 'Please check above error(s).'
      } else {
        const data = {
          memberEmail: this.memberEmail
        }
        TeamService.validateMemberEmail(data)
          .then((response) => {
            console.log(response)
            this.memberFound = response.data.member_found
            this.memberAddNew = !this.memberFound
            this.memberApplicable = response.data.member_applicable
            this.foundMemberName = response.data.member_name
            this.memberPk = response.data.member_pk
            if (response.data.status_message) {
              this.error = response.data.status_message
            }
          })
          .catch((error) => {
            this.error = error
            this.success = ''
          })
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.email = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    afterNewUserCreation (userPk) {
      this.assignCreatedByAdmin(userPk)
      this.assignExistingMemberToTeam(userPk)
    },
    backToDashboard () {
      this.$router.push('/team-dashboard')
    },
    assignCreatedByAdmin (memberPk) {
      if (memberPk) this.memberPk = memberPk
      const data = {
        memberPk: this.memberPk
      }
      TeamService.assignCreatedByAdmin(data)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          this.memberSuccessfullyAdded = false
          this.error = error
          this.success = ''
        })
    },
    assignExistingMemberToTeam (memberPk) {
      if (memberPk) this.memberPk = memberPk
      const data = {
        memberPk: this.memberPk
      }
      TeamService.assignExistingMemberToTeam(data)
        .then((response) => {
          console.log(response)
          if (response.data.updated) {
            this.memberSuccessfullyAdded = response.data.msg
            // Reset for adding the next user
            this.memberFound = false
            this.memberApplicable = false
            this.foundMemberName = false
            this.memberPk = null
            this.memberAddNew = false
          }
        })
        .catch((error) => {
          this.memberSuccessfullyAdded = false
          this.error = error
          this.success = ''
        })
    }

  },
  watch: {
    memberEmail: function (val) {
      this.memberFound = false
      this.memberApplicable = false
      this.foundMemberName = false
      this.memberPk = null
      this.memberAddNew = false
    }
  },
  components: { TopNavBar, GenderAppFooter, AddTeamMemberNewAccount }
}
</script>

<style scoped>

</style>

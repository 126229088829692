<template>
  <div>
    <TopNavBar/>

    <b-container>
      <b-row align-h="center">
        <b-col class="col-12">
          <b-card
            :title="$t('Register')"
            class="mt-5 border-success"
          >
            <b-form @reset="onReset" @submit.prevent="submit" v-if="show">
              <b-row>
                <!--First name-->
                <b-col md="6" sm="12">
                  <b-form-group
                    :label="$t('First name:')"
                    id="first_name"
                    label-for="first_name"
                  >
                    <b-form-input
                      :placeholder="$t('Enter first name')"
                      id="first_name"
                      type="text"
                      v-model="first_name"
                    ></b-form-input>
                  </b-form-group>

                  <div class="" v-if="$v.$error">
                    <small class="form-text text-danger" v-if="!$v.first_name.required">{{$t('First name is required')}}</small>
                    <small class="form-text text-danger" v-if="!$v.first_name.minLength">First name must have at least
                      {{$v.first_name.$params.minLength.min}}
                      letters.
                    </small>
                  </div>
                </b-col>

                <!--Last name-->
                <b-col md="6" sm="12">
                  <b-form-group
                    :label="$t('Last name:')"
                    id="last_name"
                    label-for="last_name"
                  >
                    <b-form-input
                      :placeholder="$t('Enter last name')"
                      id="last_name"
                      type="text"
                      v-model="last_name"
                    ></b-form-input>
                  </b-form-group>

                  <div class="" v-if="$v.$error">
                    <small class="form-text text-danger" v-if="!$v.last_name.required">{{$t('Last name is required')}}</small>
                    <small class="form-text text-danger" v-if="!$v.last_name.minLength">{{$t('Last name must have at least')}} {{$v.last_name.$params.minLength.min}} {{$t('letters')}}.
                    </small>
                  </div>
                </b-col>

                <!--Username-->
                <b-col md="12" sm="12">
                  <b-form-group
                    :label="$t('Username of team member:')"
                    id="username"
                    label-for="username"
                  >
                    <b-form-input
                      :placeholder="$t('Enter username')"
                      id="username"
                      type="text"
                      v-model="username"
                    ></b-form-input>
                  </b-form-group>

                  <div class="" v-if="$v.$error">
                    <small class="form-text text-danger" v-if="!$v.username.required">{{$t('Username is required')}}</small>
                    <small class="form-text text-danger" v-if="!$v.username.minLength">{{$t('Username must have at least')}} {{$v.username.$params.minLength.min}}
                      {{$t('letters')}}.
                    </small>
                  </div>
                </b-col>

                <!--Email-->
                <b-col md="12" sm="12">
                  <b-form-group
                    :label="$t('Email:')"
                    id="email"
                    label-for="email"
                  >
                    <b-form-input
                      :placeholder="$t('Enter email')"
                      id="email"
                      type="email"
                      v-model="email"
                    ></b-form-input>
                  </b-form-group>

                  <div class="" v-if="$v.$error">
                    <small class=" form-text text-danger" v-if="!$v.email.required">{{$t('Email is required')}}</small>
                    <small class="form-text text-danger" v-if="!$v.email.email">{{$t('Email should a valid format ex. example@mail.com')}}</small>
                  </div>
                </b-col>
              </b-row>
              <span class="mt-5"></span>
              <!--placeholder-->
              <div class="mt-2 mb-2">{{ $t('Placeholder:') }}</div>
              <table class="table">
                <tbody>
                  <tr>
                    <td class="pl-4">
                      <input class="form-check-input mt-2" type="radio" name="shortformPlaceholder" id="star" value="star" v-model="shortformPlaceholder">
                      <label class="form-check-label small" for="star">
                        Gendersternchen <div class="small text-muted">(Student*in)</div>
                      </label>
                    </td>
                    <td>
                      <input class="form-check-input mt-2" type="radio" name="shortformPlaceholder" id="colon" value="colon" v-model="shortformPlaceholder">
                      <label class="form-check-label small" for="colon">
                        Gender-Doppelpunkt <div class="small text-muted">(Student:in)</div>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td class="pl-4">
                      <input class="form-check-input mt-2" type="radio" name="shortformPlaceholder" id="lowdash" value="lowdash" v-model="shortformPlaceholder">
                      <label class="form-check-label small" for="lowdash">
                        Gender-Gap <div class="small text-muted">(Student_in)</div>
                      </label>
                    </td>
                    <td>
                      <input class="form-check-input mt-2" type="radio" name="shortformPlaceholder" id="slash" value="slash" v-model="shortformPlaceholder">
                      <label class="form-check-label small" for="slash">
                        Kurzform <div class="small text-muted">(Student/-in)</div>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
                <b-alert show variant="secondary">
                  <small class="form-text text-info" >{{ $t('This registration will not trigger a info mail to the user. After this registration, use the admin functionality \'reset password and send credentials\' to inform the user about their account & credentials.')}}</small>
                </b-alert>

              <div v-if="error">
                <b-alert show variant="danger">
                  {{error}}
                </b-alert>
              </div>

              <div v-if="success">
                <b-alert show variant="success">
                  {{success}}
                </b-alert>
              </div>

              <vue-recaptcha
                :sitekey="this.recaptchaSiteKey"
                @expired="onCaptchaExpired"
                @verify="onCaptchaVerified"
                ref="recaptcha"
                size="invisible"
              ></vue-recaptcha>
              <div class="mt-3">
                <b-button class="mr-3" type="submit" variant="primary">{{$t('Register')}}</b-button>
                <b-button type="reset" variant="danger">{{$t('Reset')}}</b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TopNavBar from '../../components/TopNavBar'
import { AuthService } from '../../services/auth.service'
import { validationMixin } from 'vuelidate'
import { email, minLength, required } from 'vuelidate/lib/validators'
import sameAs from 'vuelidate/lib/validators/sameAs'
import VueRecaptcha from 'vue-recaptcha'
import { API_URL, RECAPTCHA_SITE_KEY } from '../../env'
import axios from 'axios'

export default {
  name: 'AddTeamMemberNewAccount',
  // insert the following code for vue-meta to work
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: this.description
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.title
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.description
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: this.canonicalUrl
        },
        {
          name: 'twitter:title',
          vmid: 'twitter:title',
          content: this.title
        },
        {
          name: 'twitter:description',
          vmid: 'twitter:description',
          content: this.description
        }
      ],
      link: [
        { rel: 'canonical', href: this.canonicalUrl }
      ]
    }
  },
  props: ['memberEmail'],
  data () {
    return {
      first_name: this.memberEmail.split('@')[0].includes('.') ? this.capitalize(this.memberEmail.split('@')[0].split('.')[0]) : '',
      last_name: this.memberEmail.split('@')[0].includes('.') ? this.capitalize(this.memberEmail.split('@')[0].split('.')[1]) : '',
      username: this.memberEmail.split('@')[0],
      email: this.memberEmail,
      password1: '',
      password2: '',
      tos_accept: true,
      show: true,
      error: '',
      shortformPlaceholder: 'star',
      success: '',
      recaptchaSiteKey: RECAPTCHA_SITE_KEY,
      newsletter_enabled: false,
      title: 'Neues Teammitglied hinzufügen · gender app',
      description: 'Erstelle ein Profil bei gender app, damit deine Teammitglieder von gender app profitieren können',
      canonicalUrl: 'https://genderapp.org/add-team-member/'
    }
  },
  mixins: [validationMixin],
  validations: {
    first_name: {
      required,
      minLength: minLength(2)
    },
    last_name: {
      required,
      minLength: minLength(2)
    },
    username: {
      required,
      minLength: minLength(4)
    },
    email: {
      required,
      email
    },
    password1: {
      required,
      minLength: minLength(6)
    },
    password2: {
      required,
      sameAsPassword: sameAs('password1'),
      minLength: minLength(6)
    },
    tos_accept: {
      required
    }
  },
  methods: {
    onCaptchaVerified (recaptchaToken) {
      const token = recaptchaToken
      this.$refs.recaptcha.reset()
      // evt.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.error = this.$t('Please check above error(s).')
      } else {
        if (this.tos_accept === 'false') {
          this.error = this.$t('Please accept TOS.')
          return false
        }
        const data = {
          first_name: this.first_name,
          last_name: this.last_name,
          username: this.username,
          email: this.email,
          password: this.password1,
          shortform_placeholder: this.shortformPlaceholder,
          newsletter_enabled: this.newsletter_enabled,
          token: token
        }
        axios.post(`${API_URL}/auth/recapcha/`, { token: token })
          .then(response => {
            if (response.data.status) {
              AuthService.makeRegister(data)
                .then((response) => {
                  this.error = ''
                  this.success = this.$t('Registration successful.')
                  this.first_name = ''
                  this.last_name = ''
                  this.username = ''
                  this.email = ''
                  this.password1 = ''
                  this.password2 = ''
                  this.tos_accept = true
                  this.show = true
                  this.error = ''
                  this.shortformPlaceholder = 'star'
                  this.success = ''
                  this.$emit('newMemberCreated', response.data.id)
                })
                .catch((error) => {
                  if (error.message) {
                    for (const m of Object.values(error.message)) {
                      this.error = m[0] + ' '
                    }
                  } else {
                    this.error = error
                  }
                  this.success = ''
                })
            }
          })
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.first_name = ''
      this.last_name = ''
      this.username = ''
      this.email = ''
      this.password1 = ''
      this.password2 = ''
      this.shortformPlaceholder = 'star'
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset()
    },
    submit: function () {
      this.$refs.recaptcha.execute()
    },
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },

  watch: {
    memberEmail: function (val) {
      this.first_name = this.memberEmail.split('@')[0].includes('.') ? this.capitalize(this.memberEmail.split('@')[0].split('.')[0]) : ''
      this.last_name = this.memberEmail.split('@')[0].includes('.') ? this.capitalize(this.memberEmail.split('@')[0].split('.')[1]) : ''
      this.username = this.memberEmail.split('@')[0]
      this.email = this.memberEmail
    }
  },
  mounted () {
    const intermediatePw = Math.random().toString(36).substr(2, 7)
    this.password1 = intermediatePw
    this.password2 = intermediatePw
  },
  components: { TopNavBar, VueRecaptcha }
}
</script>

<style scoped>

</style>
